import { TRuleWithTheme } from 'styles/theme';
import uiConfig from 'features/shared/ui/ui.config';

export const sider: TRuleWithTheme<{
    isMobile: boolean;
    isVisible: boolean;
}> = ({ theme, isMobile, isVisible }) => ({
    backgroundColor: theme.colors.white,
    overflow: 'auto',

    '& .ant-layout-sider-children': {
        display: 'flex',
        width: '100%',
        height: 'auto',
        minHeight: '100%',
        alignContent: 'space-between',
        flexWrap: 'wrap'
    },

    '& .ant-layout-sider-trigger': {
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
        borderTop: `1px solid ${theme.colors.borderColor}`,
        borderRight: `1px solid ${theme.colors.borderColor}`
    },

    ...(isMobile && {
        transition: 'width 0.3s ease, min-width 0.3s ease',
        overflow: 'hidden',
        zIndex: 1000,
        position: 'absolute',
        left: 0,
        height: `calc(100% - ${uiConfig.header.mobileSize})`,
        width: isVisible
            ? `${uiConfig.sider.mobileSize} !important`
            : `0 !important`,
        minWidth: '0 !important'
        // visibility: isVisible ? 'visible' : `hidden`
        // display: isVisible ? 'block' : 'none'
    })
});

export const menu: TRuleWithTheme = ({ theme }) => ({
    height: 'auto',
    backgroundColor: theme.colors.white,
    borderRight: 'none',
    padding: '8px',

    '& .ant-menu-item': {
        marginInline: '0 !important'
    },

    '& > .ant-menu-item-group > .ant-menu-item-group-title:empty': {
        display: 'none'
    }
});

export const sideBarFooterContent: TRuleWithTheme = () => ({
    padding: `0 16px 0 28px`,
    width: '100%',
    marginBottom: '12px'
});

export const sideBarFooter: TRuleWithTheme<{
    isCollapsed: boolean;
    isMobile: boolean;
    isVisible: boolean;
}> = ({ theme, isCollapsed, isMobile, isVisible }) => ({
    transition: 'opacity 0.1s ease, visibility 0.1s ease',
    width: '100%',
    paddingLeft: '4px',
    paddingRight: '4px',

    ...(((isCollapsed && !isMobile) || (!isVisible && isMobile)) && {
        opacity: 0,
        visibility: 'hidden',
        color: theme.colors.white
    })
});

export const divider: TRuleWithTheme<{ isMobile: boolean }> = () => ({
    margin: '16px 0'
});

export const version: TRuleWithTheme = () => ({
    textDecoration: 'underline',
    cursor: 'help'
});
