import {
    doesMatchScreenSize,
    useMatchScreenSize
} from 'common/hooks/useMatchScreenSize';
import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import publicRoutes from 'app/core/AppRouter/publicRoutes';
import protectedRoutes from 'app/core/AppRouter/protectedRoutes';
import { matchPath } from 'react-router-dom';

const ScreenSizeNotCollapsed = 'lg';

const useSideBar = () => {
    const [isCollapsed, setCollapsed] = useState<boolean>(
        !doesMatchScreenSize(ScreenSizeNotCollapsed)
    );

    const isDesktop = useMatchScreenSize(ScreenSizeNotCollapsed);
    const isTablet = useMatchScreenSize('sm');
    const isMobile = useMatchScreenSize('xs');

    useEffect(() => {
        if (isDesktop) {
            setCollapsed(false);
        }
    }, [isDesktop]);

    useEffect(() => {
        if (isTablet && !isDesktop) {
            setCollapsed(true);
        }
    }, [isTablet, isDesktop]);

    const { pathname } = useLocation();
    const selectedKeys = useMemo(
        () =>
            [...publicRoutes, ...protectedRoutes]
                .filter((route) =>
                    matchPath({ path: route.path!, end: true }, pathname)
                )
                .map((route) => route.path)
                .filter(Boolean),
        [pathname]
    ) as string[];

    return {
        isCollapsed,
        setCollapsed,
        isMobile,
        selectedKeys
    };
};

export default useSideBar;
