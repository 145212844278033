import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from 'app/services/api/auth';
import { RootState } from 'app/store';
import type { User } from 'interfaces/User.entity';

type AuthState = {
    user: User | null;
    loggedOut: boolean | null;
    // token: string | null;
};

const slice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        loggedOut: null
    } as AuthState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state, { payload }: PayloadAction<User>) => {
                state.user = payload;
                state.loggedOut = false;
            }
        );
        builder.addMatcher(
            authApi.endpoints.getMe.matchFulfilled,
            (state, { payload }: PayloadAction<User>) => {
                state.user = payload;
                state.loggedOut = false;
            }
        );
        builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
            state.user = null;
            state.loggedOut = true;
        });
    }
});

export default slice.reducer;

export const selectUserLoggedOut = (state: RootState) => state.auth.loggedOut;
export const selectCurrentUser = (state: RootState) => state.auth.user;
