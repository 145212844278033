import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from 'features/shared/auth/authSlice';
import networkReducer, {
    monitorNetworkState
} from 'features/shared/network/networkSlice';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import config from 'config/index.js';
import browserHistory from 'app/services/utils/browserHistory';

import middlewares from './middlewares/index.js';
import { authApi } from 'app/services/api/auth';
import { createReduxHistoryContext } from 'redux-first-history';
import sentryMiddleware from 'app/middlewares/sentry.middleware.js';

let sentryReduxEnhancer: any = null;

if (config.sentry.dsn) {
    sentryReduxEnhancer = sentryMiddleware;
}

const { createReduxHistory, routerReducer, routerMiddleware } =
    createReduxHistoryContext({ history: browserHistory });

export const store = configureStore({
    reducer: combineReducers({
        router: routerReducer,
        [authApi.reducerPath]: authApi.reducer,
        network: networkReducer,
        auth: authReducer
    }),
    devTools: config.devTools,
    enhancers: (getDefaultEnhancer) =>
        getDefaultEnhancer().concat(...[sentryReduxEnhancer].filter(Boolean)),
    middleware: (getDefaultMiddleware) =>
        middlewares(getDefaultMiddleware).prepend(routerMiddleware)
});

setupListeners(store.dispatch);
monitorNetworkState(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const history = createReduxHistory(store);
