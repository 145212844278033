import * as React from 'react';

import * as felaRules from './SideBar.styles.js';
import useFelaEnhanced from 'common/hooks/useFelaEnhanced';
import { Divider, Layout, Menu, Space, Tooltip, Typography } from 'antd';
import Overlay from 'features/shared/ui/Overlay';
import useSideBar from 'features/shared/layout/hooks/useSideBar';
import { Trans } from '@lingui/macro';
import config from 'config/index.js';
import DateFormat from 'features/shared/localization/DateFormat';
import { ReactNode } from 'react';

interface SideBarProps {
    children: ReactNode;
    extend?: typeof felaRules;
    isVisible: boolean;
    setVisible: (isVisible: boolean) => void;
}

const SideBar: React.FC<SideBarProps> = ({
    children,
    extend,
    isVisible,
    setVisible
}) => {
    const { isCollapsed, setCollapsed, isMobile, selectedKeys } = useSideBar();

    const { styles } = useFelaEnhanced(felaRules, {
        extend,
        isVisible,
        isMobile,
        isCollapsed
    });

    return (
        <>
            <Overlay
                isVisible={isMobile && isVisible}
                onClick={() => setVisible(false)}
            />
            <Layout.Sider
                theme={'light'}
                collapsible={!isMobile}
                collapsed={!isMobile && isCollapsed}
                onCollapse={setCollapsed}
                className={styles.sider}
            >
                <Menu
                    id="menu"
                    mode="inline"
                    subMenuOpenDelay={0}
                    className={styles.menu}
                    // @ts-expect-error types
                    selectedKeys={selectedKeys[0]}
                    defaultActiveFirst={false}
                    forceSubMenuRender={true}
                    onSelect={() => setVisible(false)}
                >
                    {children}
                </Menu>

                <div className={styles.sideBarFooter}>
                    <Divider className={styles.divider} />

                    <Space
                        direction={'vertical'}
                        className={styles.sideBarFooterContent}
                    >
                        <Typography.Text type="secondary">
                            <Trans>Verze:</Trans>{' '}
                            <Tooltip
                                placement="topLeft"
                                title={
                                    <Trans>
                                        Vydáno dne{' '}
                                        <DateFormat
                                            value={config.versionReleaseDate}
                                        />
                                    </Trans>
                                }
                            >
                                <span className={styles.version}>
                                    {config.version}
                                </span>
                            </Tooltip>
                        </Typography.Text>
                    </Space>
                </div>
            </Layout.Sider>
        </>
    );
};

export default SideBar;
