import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import config from 'config/index.js';
import logger from 'common/utils/logger';
import { notification } from 'antd';
import { t } from '@lingui/macro';
import { selectNetworkState } from 'features/shared/network/networkSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: config.apiBaseUrl,
    credentials: 'include'
});

const baseQueryWithAuth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result: ReturnType<typeof baseQuery>;

    const isLoginRequest =
        typeof args === 'object' &&
        args.url.includes(config.apiEndpoints.login);

    try {
        result = await baseQuery(args, api, extraOptions);

        if (result.error && !isLoginRequest) {
            switch (result.error.status) {
                case 'FETCH_ERROR': {
                    // TODO: check if user is online/offline
                    const { isOnline } = selectNetworkState(
                        api.getState() as any
                    );
                    if (isOnline) {
                        logger.error(`API Down`);
                        notification.error({
                            placement: 'topRight',
                            message: t`Vyskytla se chyba!`,
                            description: t`Server je momentálně nedostupný, opakujte akci později.`
                        });
                    }
                    break;
                }
                case 401: {
                    // try to get a new token
                    const refreshResult = await baseQuery(
                        {
                            body: undefined,
                            url: config.apiEndpoints.refresh,
                            method: 'POST',
                            credentials: 'include'
                        },
                        api,
                        {}
                    );
                    if (refreshResult.data) {
                        // retry the initial query
                        result = await baseQuery(args, api, extraOptions);
                    } else {
                        api.dispatch({
                            type: 'auth/logout'
                        });
                    }
                    break;
                }
                case 403: {
                    logger.error(result.error);
                    notification.error({
                        placement: 'topRight',
                        message: t`Vyskytla se chyba!`,
                        description: t`Pro tuto akci nemáte dostatečná oprávnění.`
                    });
                    break;
                }
                // Cannot be generalized!
                // case 404: {
                //     logger.error(result.error);
                //     notification.error({
                //         placement: 'topRight',
                //         message: t`Vyskytla se chyba!`,
                //         description: t`Požadovaná funkce není implementována.`
                //     });
                //     break;
                // }
                case 500: {
                    logger.error(result.error);
                    notification.error({
                        placement: 'topRight',
                        message: t`Vyskytla se chyba!`,
                        description: t`Vnitřní chyba serveru.`
                    });
                    break;
                }
            }
        }

        return result;
    } catch (e) {
        notification.error({
            placement: 'topRight',
            message: t`Vyskytla se chyba!`,
            description: t`Ověřte prosím připojení k internetu a akci opakujte později.`
        });

        throw e;
    }
};

export enum TagTypes {
    Users = 'Users',
    Units = 'Units',
    UnitsCache = 'UnitsCache',
    UnitsCacheSettings = 'UnitsCacheSettings',
    Clouds = 'Clouds',
    UNAUTHORIZED = 'UNAUTHORIZED',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithAuth,
    endpoints: () => ({}),
    tagTypes: Object.values(TagTypes)
});

export default api;
