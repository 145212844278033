import { authApi } from 'app/services/api/auth';
import { isEnvDevelopment } from 'environments';

export default function middlewares(getDefaultMiddleware: any) {
    const extraMiddlewares = [authApi.middleware];
    if (isEnvDevelopment) {
        // extraMiddlewares.push(require('redux-logger').createLogger());
    }

    return getDefaultMiddleware().concat(extraMiddlewares);
}
