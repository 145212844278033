import * as React from 'react';
import { useLocation } from 'react-router';
import { useAuth } from 'common/hooks/useAuth';
import config from 'config/index.js';
import { Navigate } from 'react-router-dom';
import { Role } from 'interfaces/role.enum';

const RedirectCheck: React.FC<any> = ({ children }) => {
    const location = useLocation();
    const { user } = useAuth();

    const isOnLoginPage = location.pathname.match(config.routes.login);

    if (isOnLoginPage && user) {
        return (
            <Navigate
                to={
                    user.role.includes(Role.Admin)
                        ? config.routes.admin.dashboard
                        : config.routes.home
                }
            />
        );
    }

    return children;
};

export default RedirectCheck;
