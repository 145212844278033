import * as React from 'react';
import { ReactElement, useEffect } from 'react';
import { useAuth } from 'common/hooks/useAuth';
import { useGetMeQuery } from 'app/services/api/auth';
import Loader from 'common/components/Loader';
import { AuthAbilityContext } from 'features/shared/auth/authAbilityContext';
import { Ability } from '@casl/ability';
import { AppAbility } from 'features/shared/auth/auth.config';
import { unpackRules } from '@casl/ability/extra';

export interface AuthCheckProps {
    children: ReactElement;
}

const LOGO_SHOW_DELAY = 1_000;

const ability = new Ability() as AppAbility;

// Validate if current user is authenticated and if not, try to fetch him
const AuthCheck: React.FC<AuthCheckProps> = ({ children }) => {
    const { user, loggedOut } = useAuth();
    const { error } = useGetMeQuery(undefined, {
        skip: Boolean(loggedOut) || Boolean(user)
    });

    const { caslRules } = user || {};

    useEffect(() => {
        if (caslRules) {
            const rules = unpackRules(caslRules);
            ability.update(rules as any);
        } else {
            ability.update([]);
        }
    }, [caslRules]);

    if (error || user || loggedOut) {
        return (
            <AuthAbilityContext.Provider value={ability}>
                {children}
            </AuthAbilityContext.Provider>
        );
    }

    return <Loader showAfter={LOGO_SHOW_DELAY} />;
};

export default AuthCheck;
