import { HTMLProps } from 'react';
import * as React from 'react';

import * as felaRules from './Overlay.styles.js';
import useFelaEnhanced from 'common/hooks/useFelaEnhanced';

export interface OverlayProps extends HTMLProps<HTMLDivElement> {
    extend?: typeof felaRules;
    isVisible?: boolean;
}

const Overlay: React.FC<OverlayProps> = ({
    extend,
    isVisible = true,
    ...props
}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, isVisible });

    return <div {...props} className={styles.container} />;
};

export default Overlay;
