import * as React from 'react';
import { Menu } from 'antd';
import {
    CloudOutlined,
    DeploymentUnitOutlined,
    HddOutlined,
    HomeOutlined,
    SettingOutlined,
    UserOutlined
} from '@ant-design/icons';
import config from 'config/index.js';
import { Trans } from '@lingui/macro';
import MenuLink from 'features/shared/layout/AppLayout/MenuLink';

const SideMenu: React.FC = () => {
    return (
        <Menu.ItemGroup key="admin">
            <Menu.Item
                icon={<HomeOutlined />}
                key={config.routes.admin.dashboard}
            >
                <MenuLink to={config.routes.admin.dashboard}>
                    <Trans>Přehled</Trans>
                </MenuLink>
            </Menu.Item>

            <Menu.Item
                icon={<CloudOutlined />}
                key={config.routes.admin.clouds}
            >
                <MenuLink to={config.routes.admin.clouds}>
                    <Trans>Cloudy</Trans>
                </MenuLink>
            </Menu.Item>

            <Menu.Item icon={<UserOutlined />} key={config.routes.admin.users}>
                <MenuLink to={config.routes.admin.users}>
                    <Trans>Uživatelé</Trans>
                </MenuLink>
            </Menu.Item>

            <Menu.SubMenu
                icon={<DeploymentUnitOutlined />}
                key={config.routes.admin.units}
                title={<Trans>Jednotky</Trans>}
            >
                <Menu.Item
                    icon={<HddOutlined />}
                    key={config.routes.admin.unitsKronos}
                >
                    <MenuLink to={config.routes.admin.unitsKronos}>
                        <Trans>Kronos</Trans>
                    </MenuLink>
                </Menu.Item>

                <Menu.Item
                    icon={<HddOutlined />}
                    key={config.routes.admin.unitsFarmsoft}
                >
                    <MenuLink to={config.routes.admin.unitsFarmsoft}>
                        <Trans>Farmsoft</Trans>
                    </MenuLink>
                </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
                icon={<SettingOutlined />}
                key={Object.keys(config.routes.admin.settings).join('-')}
                title={<Trans>Nastavení</Trans>}
            >
                <Menu.Item
                    icon={<DeploymentUnitOutlined />}
                    key={config.routes.admin.settings.unitsFarmsoft}
                >
                    <MenuLink to={config.routes.admin.settings.unitsFarmsoft}>
                        <Trans>Farmsoft</Trans>
                    </MenuLink>
                </Menu.Item>
            </Menu.SubMenu>
        </Menu.ItemGroup>
    );
};

export default SideMenu;
