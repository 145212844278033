import felaPluginValidator from 'fela-plugin-validator';
import felaPluginUnit from 'fela-plugin-unit';
import felaPluginNamedKeys from 'fela-plugin-named-keys';
import felaPluginFriendlyPseudoClass from 'fela-plugin-friendly-pseudo-class';
import felaPluginExpandShorthand from 'fela-plugin-expand-shorthand';

import namedKeys from './namedKeys.js';

import { isEnvDevelopment } from '../../environments/index.js';

const defaultUnit = 'rem';

const plugins = [
    // docs: https://github.com/rofrischmann/fela/tree/master/packages/fela-plugin-unit
    felaPluginUnit(defaultUnit),

    // docs: https://github.com/rofrischmann/fela/tree/master/packages/fela-plugin-friendly-pseudo-class
    felaPluginFriendlyPseudoClass(),

    // docs: https://github.com/rofrischmann/fela/tree/master/packages/fela-plugin-friendly-pseudo-class
    felaPluginNamedKeys(namedKeys),

    felaPluginExpandShorthand(true),

    // felaPluginValidator should be the last plugin
    isEnvDevelopment &&
        felaPluginValidator({
            logInvalid: true,
            deleteInvalid: true,
            useCSSLint: false
        })
].filter(Boolean);

export default plugins;
