import { globals, resets, theme, vendors } from '../../styles/index.js';
import plugins from './plugins.js';
import enhancers from './enhancers.js';
import { IConfig } from 'fela';
import { isEnvDevelopment } from 'environments';

export { theme };

export const rendererConfig: IConfig = {
    devMode: isEnvDevelopment,
    enhancers,
    plugins: plugins as any
};

export const staticCSS = [resets, vendors, globals];

interface IFont {
    family: string;
    files: Array<string>;
    options?: object;
}

export const fonts: IFont[] = [
    // {
    //     name: '',
    //     files: [],
    //     options: {}
    // }
];
