import { useAuth } from 'common/hooks/useAuth';
import { Role } from 'interfaces/role.enum';
import config from 'config/index.js';
import { useMemo } from 'react';

export default function useUserBasePath() {
    const { user } = useAuth();

    return useMemo(() => {
        if (!user) {
            return config.routes.login;
        }

        return user.role.includes(Role.Admin)
            ? config.routes.admin.dashboard
            : config.routes.home;
    }, [user]);
}
