import type { TRule } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

export const layout: TRule = () => ({
    padding: '0 24px 12px',
    height: '100%',
    width: '100%'
});

export const content: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    overflow: 'auto'
});
