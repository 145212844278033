import { useState, useEffect } from 'react';
import namedKeys, { FelaNamedKeys } from 'config/fela/namedKeys';

export const doesMatchScreenSize = (type: FelaNamedKeys): boolean => {
    const namedKey = namedKeys[type].replace('@media', '');
    return window.matchMedia(namedKey).matches;
};

export const useMatchScreenSize = (type: FelaNamedKeys): boolean => {
    const [matchSize, setMachSize] = useState<boolean>(
        doesMatchScreenSize(type)
    );

    useEffect(() => {
        const namedKey = namedKeys[type].replace('@media', '');
        const mq = window.matchMedia(namedKey);

        setMachSize(Boolean(window.matchMedia(namedKey).matches));

        const handleChange = (e: MediaQueryListEvent) => {
            setMachSize(e.matches);
        };

        if ('addEventListener' in mq) {
            mq.addEventListener('change', handleChange);
            return () => mq.removeEventListener('change', handleChange);
        } else {
            // Safari < 14
            // @ts-expect-error xxx
            mq.addListener(handleChange);
            // @ts-expect-error xxx
            return () => mq.removeListener(handleChange);
        }
    }, [type]);

    return matchSize;
};
