import { TRuleWithTheme } from 'styles/theme';

export const logo: TRuleWithTheme<{
    animationName?: string;
    animate?: boolean;
}> = ({ animationName, animate }) => ({
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',

    ...(animate && {
        animation: `2000ms ${animationName} 1 linear`,
        animationIterationCount: `infinite`
    })
});
