import { FunctionComponent, Suspense } from 'react';
import Loader from 'common/components/Loader';
import AppLayout from 'features/shared/layout/AppLayout';

const ProtectedRouteWrapper = (Component: FunctionComponent) => {
    return (
        <AppLayout>
            <Suspense fallback={<Loader showAfter={100} inline />}>
                <Component />
            </Suspense>
        </AppLayout>
    );
};

export default ProtectedRouteWrapper;
