const routes = {
    admin: {
        dashboard: '/admin',
        units: '/admin/units',

        users: '/users',
        userProfile: '/users/:id',

        clouds: '/admin/clouds',
        cloudsDetail: '/admin/clouds/:id',

        unitsFarmsoft: '/admin/units/farmsoft',
        unitsKronos: '/admin/units/kronos',

        settings: {
            unitsFarmsoft: '/admin/settings/units/farmsoft'
        }
    },

    selfProfile: '/users/me',

    home: '/dashboard',
    login: '/login'
};

export const anchors = {
    admin: {
        cloud: {
            users: 'users',
            units: 'units'
        }
    }
};

export default routes;
