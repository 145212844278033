import { Button as AntdButton, Result as RequestResult } from 'antd';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import Routes from 'config/routes';

export default function PermissionDeniedPage() {
    return (
        <RequestResult
            status="403"
            title="403"
            subTitle={
                <Trans>
                    Pro navštívění této stránky nemáte dostatečné oprávnění!
                </Trans>
            }
            extra={
                <AntdButton>
                    <Link to={Routes.home}>
                        <Trans>Vrátit se zpět</Trans>
                    </Link>
                </AntdButton>
            }
        />
    );
}
