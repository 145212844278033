import { TRuleWithTheme } from 'styles/theme';
import type { OverlayProps } from './Overlay.js';

export const container: TRuleWithTheme<Pick<OverlayProps, 'isVisible'>> = ({
    isVisible
}) => ({
    zIndex: 20,
    content: "' '",
    width: '100%',
    height: '100%',
    background: 'black',
    left: 0,
    display: 'block',
    position: 'fixed',
    transition: 'visibility 0.3s linear,opacity 0.3s linear',

    ...(isVisible
        ? {
              visibility: 'visible',
              opacity: 0.3
          }
        : {
              visibility: 'hidden',
              opacity: 0
          })
});
