import { Layout } from 'antd';
import type { PropsWithChildren } from 'react';
import { useState, useCallback } from 'react';

import * as felaRules from './AppLayout.styles.js';
import useFelaEnhanced from 'common/hooks/useFelaEnhanced';
import Header from 'features/shared/layout/AppLayout/Header';
import SideBar from 'features/shared/layout/AppLayout/SideBar';
import { Can } from 'features/shared/auth/authAbilityContext';
import { Action } from 'features/shared/auth/auth.config';
import AdminSideMenu from 'features/admin/menu/SideMenu/SideMenu';
import UserSideMenu from 'features/user/menu/SideMenu/SideMenu';

const { Content } = Layout;

export default function AppLayout({ children }: PropsWithChildren<any>) {
    const { styles } = useFelaEnhanced(felaRules);
    const [isSidebarVisible, setSidebarVisible] = useState<boolean>(false);

    const toggleSidebarVisibility = useCallback(() => {
        setSidebarVisible((oldValue) => !oldValue);
    }, []);

    return (
        <Layout>
            <Header toggleSidebarVisibility={toggleSidebarVisibility} />
            <Layout>
                <SideBar
                    isVisible={isSidebarVisible}
                    setVisible={setSidebarVisible}
                >
                    <Can I={Action.Manage} a={'all'}>
                        <AdminSideMenu />
                    </Can>

                    <Can not I={Action.Manage} a={'all'}>
                        <UserSideMenu />
                    </Can>
                </SideBar>

                <Layout className={styles.layout}>
                    <Content className={styles.content}>
                        <div className={styles.contentWrapper}>{children}</div>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
}
