import * as React from 'react';
import { useFela } from 'react-fela';
import { TKeyFrame } from 'fela';

export interface FelaKeyFrameProps {
    children: any;
    keyframe: TKeyFrame;
}
const FelaKeyframe: React.FC<FelaKeyFrameProps> = ({
    children,
    keyframe,
    ...props
}) => {
    const { renderer, theme } = useFela();
    const animationName = React.useMemo(
        () => renderer.renderKeyframe(keyframe, { ...props, theme }),
        [keyframe, renderer, theme, props]
    );
    return children(animationName);
};

export default FelaKeyframe;
