import { createContext } from 'react';
import * as React from 'react';
import { createContextualCan } from '@casl/react';
import { AppAbility } from 'features/shared/auth/auth.config';

export const AuthAbilityContext = createContext<AppAbility>(undefined!);

export const Can = createContextualCan(AuthAbilityContext.Consumer);

export type CanProps = React.ComponentProps<typeof Can>;
