import { CoreProps } from './types.js';
import { RendererProvider, ThemeProvider } from 'react-fela';
import { createRenderer } from 'fela';
import { fonts, rendererConfig, staticCSS, theme } from 'config/fela';
import { setTwoToneColor } from '@ant-design/icons';
import { useLayoutEffect } from 'react';
import { ConfigProvider } from 'antd';

const felaRenderer = createRenderer(rendererConfig);

const Fela = ({ children }: CoreProps) => {
    // Render static styles & fonts
    useLayoutEffect(() => {
        staticCSS.forEach((css) => felaRenderer.renderStatic(css.toString()));
        fonts.forEach((font) =>
            felaRenderer.renderFont(font.family, font.files, font.options)
        );

        setTwoToneColor(theme.colors.primaryIcon);

        return () => felaRenderer.clear();
    }, []);

    return (
        <RendererProvider renderer={felaRenderer}>
            <ThemeProvider theme={theme}>
                <ConfigProvider
                    form={{
                        scrollToFirstError: true,
                        requiredMark: true
                    }}
                    theme={{
                        token: {
                            colorPrimary: theme.colors.primary,
                            colorLink: theme.colors.primary,
                            colorLinkHover: theme.colors.primary,
                            colorLinkActive: theme.colors.primary
                        }
                    }}
                >
                    {children}
                </ConfigProvider>
            </ThemeProvider>
        </RendererProvider>
    );
};

export default Fela;
