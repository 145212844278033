import * as React from 'react';

import * as felaRules from './Footer.styles.js';
import useFelaEnhanced from 'common/hooks/useFelaEnhanced';

import { Typography } from 'antd';
import { Trans } from '@lingui/macro';

interface HeaderProps {
    extend?: typeof felaRules;
}

const Footer: React.FC<HeaderProps> = ({ extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <footer className={styles.container}>
            <Typography.Text type="secondary">
                <Trans>
                    Copyright by Farmtec &copy;&nbsp;
                    {new Date().getUTCFullYear()}
                </Trans>
            </Typography.Text>
        </footer>
    );
};

export default Footer;
