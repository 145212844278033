import * as React from 'react';

import * as felaRules from './SideMenu.styles.js';
import { Menu } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import config from 'config/index.js';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';

interface SideBarProps {
    extend?: typeof felaRules;
}

const SideMenu: React.FC<SideBarProps> = () => {
    // const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <Menu.ItemGroup key="user">
            <Menu.Item icon={<HomeOutlined />} key={config.routes.home}>
                <Link to={config.routes.home}>
                    <Trans>Přehled</Trans>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
    );
};

export default SideMenu;
