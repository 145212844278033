import { default as felaMonolithic } from 'fela-monolithic';
import { TEnhancer } from 'fela';
import { isEnvDevelopment } from '../../environments/index.js';

const devEnhancers = isEnvDevelopment
    ? [
          felaMonolithic({
              prettySelectors: true
          })
      ]
    : [];

const enhancers: TEnhancer[] = [...devEnhancers];

export default enhancers;
