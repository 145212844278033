import * as React from 'react';

import * as felaRules from './Navbar.styles.js';
import useFelaEnhanced from 'common/hooks/useFelaEnhanced';
import { Menu } from 'antd';
import UserSettings from 'features/shared/layout/AppLayout/UserSettings';
import { useMatchScreenSize } from 'common/hooks/useMatchScreenSize';

interface NavbarProps {
    extend?: typeof felaRules;
}

const Navbar: React.FC<NavbarProps> = ({ extend }) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });

    const isDesktop = useMatchScreenSize('lg');

    return (
        <Menu
            mode="horizontal"
            className={styles.menuLeft}
            defaultActiveFirst={false}
            triggerSubMenuAction={isDesktop ? 'hover' : 'click'}
        >
            <UserSettings extend={{ container: rules.menuRight }} />
        </Menu>
    );
};

export default Navbar;
