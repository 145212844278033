import * as React from 'react';
import { Can, CanProps } from 'features/shared/auth/authAbilityContext';
import PermissionDeniedPage from 'pages/shared/PermissionDeniedPage';

export type CanWithErrorPageProps = {
    render: () => React.ReactNode;
} & CanProps;

const CanWithErrorPage = ({
    render,
    ...props
}: CanWithErrorPageProps): JSX.Element => {
    return (
        <Can {...props} passThrough>
            {(allowed: boolean) =>
                allowed ? render() : <PermissionDeniedPage />
            }
        </Can>
    );
};

export default CanWithErrorPage;
