import * as loglevel from 'loglevel';
import * as Sentry from '@sentry/browser';
import { isEnvProduction } from 'environments';

isEnvProduction ? loglevel.disableAll(false) : loglevel.enableAll(false);

const { warn, info, trace, debug } = loglevel;

const error = (err: any) => {
    if (err?.status === 'FETCH_ERROR') {
        console.debug(
            `Skipping logging error because user is probably offline.`
        );
        return;
    }

    loglevel.error(err);
    Sentry.captureException(err);
};

const logger = {
    warn,
    info,
    trace,
    debug,
    error
};

export default logger;
