import { useMemo } from 'react';
import { useAuth } from 'common/hooks/useAuth';
import protectedRoutes from './protectedRoutes/index.js';

const useProtectedRoutes = () => {
    const { user } = useAuth();
    const userRoles = user?.role;

    return useMemo(() => {
        return protectedRoutes.filter(({ allowedRoles }) => {
            if (!allowedRoles || allowedRoles.length === 0) {
                return true;
            }

            return (userRoles || []).some((userRole) =>
                allowedRoles.includes(userRole)
            );
        });
    }, [userRoles]);
};

export default useProtectedRoutes;
