import * as React from 'react';

import * as felaRules from './UserSettings.styles.js';
import useFelaEnhanced from 'common/hooks/useFelaEnhanced';
import { Avatar, Menu } from 'antd';
import {
    PoweroffOutlined,
    SettingOutlined,
    UserOutlined
} from '@ant-design/icons';
import { useAuth } from 'common/hooks/useAuth';
import { t } from '@lingui/macro';
import { useLogoutMutation } from 'app/services/api/auth';
import config from 'config/index.js';
import { NavLink } from 'react-router-dom';

const { SubMenu, Divider } = Menu;

interface UserSettingsProps {
    extend?: Partial<typeof felaRules>;
}

const UserSettings: React.FC<UserSettingsProps> = ({ extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    const { user } = useAuth();
    const [apiLogout] = useLogoutMutation();

    return (
        <SubMenu
            key={'submenu_top'}
            className={styles.container}
            title={
                <div className={styles.avatarContainer}>
                    <Avatar
                        icon={<UserOutlined />}
                        className={styles.avatarIcon}
                    />
                    &nbsp;
                    <span className={styles.userFirstName}>
                        {user!.jmeno}&nbsp;
                    </span>
                    <span className={styles.userLastName}>
                        <span>{user!.prijmeni}</span>
                    </span>
                </div>
            }
        >
            <Menu.Item key="settings_link" icon={<SettingOutlined />}>
                <NavLink to={config.routes.selfProfile}>{t`Nastavení`}</NavLink>
            </Menu.Item>
            <Divider />
            <Menu.Item
                key="logout_link"
                icon={<PoweroffOutlined />}
                onClick={() => apiLogout(undefined)}
            >
                {t`Odhlásit se`}
            </Menu.Item>
        </SubMenu>
    );
};

export default UserSettings;
