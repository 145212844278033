import config from 'config/index.js';
import { Role } from 'interfaces/role.enum';
import { lazy } from 'react';
import type { ProtectedRoute } from 'app/core/AppRouter/protectedRoutes/routes.interface';
import ProtectedRouteWrapper from 'app/core/AppRouter/protectedRoutes/ProtectedRouteWrapper';

const SelfProfileSettingsPage = lazy(
    () => import('pages/user/ProfileSettings/SelfProfileSettingsPage')
);

const Dashboard = lazy(() => import('pages/user/Dashboard'));

const protectedUserRoutes: ProtectedRoute[] = [
    {
        path: config.routes.home,
        element: ProtectedRouteWrapper(Dashboard),
        allowedRoles: [Role.User]
    },
    {
        path: config.routes.selfProfile,
        element: ProtectedRouteWrapper(SelfProfileSettingsPage),
        allowedRoles: [Role.User, Role.Admin]
    }
];

export default protectedUserRoutes;
