// TODO: migrate from BE automatically

import { Ability, InferSubjects } from '@casl/ability';
import { User } from 'interfaces/User.entity';

export enum Action {
    Manage = 'manage',
    Create = 'create',
    Detail = 'detail',
    List = 'list',
    Update = 'update',
    Delete = 'delete'
}

export type Subjects =
    | 'Cloud'
    | 'User'
    | 'UnitEntity'
    | 'all'
    | InferSubjects<Partial<User>>;

export type AppAbility = Ability<[Action, Subjects]>;
// export const AppAbility = Ability as AbilityClass<AppAbility>;
