import { Provider as Redux } from 'react-redux';
import { HistoryRouter as ConnectedRouter } from 'redux-first-history/rr6';
import Fela from 'app/core/Fela';
import Lingui from 'app/core/Lingui';
import AppRouter from 'app/core/AppRouter';
import { store } from 'app/store';
import AuthCheck from 'features/shared/auth/AuthCheck';
import RedirectCheck from 'features/shared/auth/RedirectCheck';
import browserHistory from 'app/services/utils/browserHistory';
import NetworkCheck from 'features/shared/network/components/NetworkCheck';

// import reportWebVitals from './reportWebVitals';

export default function App() {
    return (
        <Redux store={store}>
            <ConnectedRouter history={browserHistory}>
                <NetworkCheck />
                <Fela>
                    <Lingui>
                        <AuthCheck>
                            <RedirectCheck>
                                <AppRouter />
                            </RedirectCheck>
                        </AuthCheck>
                    </Lingui>
                </Fela>
            </ConnectedRouter>
        </Redux>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
