import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.js';
import { isEnvDevelopment } from 'environments';

// import reportWebVitals from './reportWebVitals';
function renderApp(): void {
    const container = document.getElementById('root')!;
    const root = createRoot(container);
    root.render(
        <StrictMode>
            <App />
        </StrictMode>
    );
}

async function initSentry(): Promise<void> {
    const dsn = false as any;

    if (dsn) {
        const Sentry = await import('@sentry/react');
        // const { Integrations } = await import('@sentry/tracing');
        // const browserHistory = await import(
        //     'app/services/utils/browserHistory'
        // );

        Sentry.init({
            dsn,
            debug: false,
            environment: isEnvDevelopment ? 'development' : 'production',
            release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
            integrations: [
                // new Integrations.BrowserTracing({
                //     routingInstrumentation:
                //         Sentry.reactRouterV5Instrumentation(browserHistory)
                // })
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0
        });
        console.info(`Sentry initiated!`);
    } else {
        console.warn(`Sentry not initiated! DSN missing.`);
    }
}

async function loadPolyfills() {
    if (
        'scrollBehavior' in document.documentElement.style &&
        // @ts-expect-error xxx
        window.__forceSmoothScrollPolyfill__ !== true
    ) {
        return;
    }

    const smoothScroll = await import('smoothscroll-polyfill');
    smoothScroll.polyfill();
}

Promise.all([initSentry(), loadPolyfills()]).then(renderApp).catch(renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
