import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    selectCurrentUser,
    selectUserLoggedOut
} from 'features/shared/auth/authSlice';
import { User } from 'interfaces/User.entity';

export interface UseAuthType {
    user: User | null;
    loggedOut: boolean | null;
}

export const useAuth = () => {
    const user = useSelector(selectCurrentUser);
    const loggedOut = useSelector(selectUserLoggedOut);

    return useMemo<UseAuthType>(() => ({ user, loggedOut }), [user, loggedOut]);
};
