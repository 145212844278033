import * as React from 'react';
import useFelaEnhanced from 'common/hooks/useFelaEnhanced';

import * as felaRules from './AnimatedLogo.styles.js';
import Logo from 'common/components/Icons/Logo';
import FelaKeyframe from 'common/components/FelaKeyFrame';
import { FelaComponent } from 'react-fela';
import { TKeyFrame } from 'fela';

export interface LoaderProps {
    extend?: typeof felaRules;
    children?: any;
    isVisible?: boolean;
    animate?: boolean;
}

const opacityFrames: TKeyFrame = () => ({
    '0%': {
        opacity: 1
    },
    '50%': {
        opacity: 0.5
    },
    '100%': {
        opacity: 1
    }
});

const AnimatedLogo: React.FC<LoaderProps> = ({
    extend,
    animate = true,
    isVisible,
    children
}) => {
    const { rules, styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <FelaKeyframe keyframe={opacityFrames}>
            {(animationName: string) => (
                <FelaComponent
                    animationName={animationName}
                    animate={animate}
                    style={rules.logo}
                >
                    <Logo className={styles.logo} />
                </FelaComponent>
            )}
        </FelaKeyframe>
    );
};

export default AnimatedLogo;
