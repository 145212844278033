import type { RouteProps } from 'react-router';
import { FunctionComponent, lazy, Suspense } from 'react';
import config from 'config/index.js';
import { Navigate } from 'react-router-dom';
import Loader from 'common/components/Loader';
import PublicLayout from 'features/shared/layout/PublicLayout';
import NotFoundPage from 'pages/shared/NotFoundPage';

const PublicRouteWrapper = (Component: FunctionComponent) => {
    return (
        <PublicLayout>
            <Suspense fallback={<Loader showAfter={100} inline />}>
                <Component />
            </Suspense>
        </PublicLayout>
    );
};

const publicRoutes: Array<RouteProps> = [
    {
        path: config.routes.login,
        element: PublicRouteWrapper(
            lazy(() => import('pages/shared/LoginPage'))
        )
    },
    {
        path: '/',
        element: <Navigate to={config.routes.login} />
    },
    ...config.allRoutesPath.map((path: string) => ({
        path,
        element: <Navigate to={config.routes.login} />
    })),
    {
        path: '*',
        element: PublicRouteWrapper(NotFoundPage)
    }
];

export default publicRoutes;
