import { Route, Routes } from 'react-router-dom';

//import publicRoutes from 'app/core/AppRouter/publicRoutes.js';
import publicRoutes from 'app/core/AppRouter/publicRoutes';
import useProtectedRoutes from 'app/core/AppRouter/useProtectedRoutes';

export default function AppRouter() {
    const protectedRoutes = useProtectedRoutes();

    return (
        <Routes>
            {[...protectedRoutes, ...publicRoutes]
                .filter((route) => route.path)
                .map((route) => (
                    <Route key={String(route.path)} {...route} />
                ))}
        </Routes>
    );
}
