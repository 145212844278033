import type { TRule } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

export const layout: TRule = () => ({
    padding: '0'
});

export const container: TRuleWithTheme = ({ theme }) => ({
    padding: 24,
    margin: 0,
    minHeight: 280,
    maxHeight: '100%'
});

export const content: TRule = () => ({
    margin: 0,
    minHeight: 10,
    overflow: 'auto',

    sm: {
        padding: 1.5
    }
});

export const contentWrapper: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.white,
    padding: `1.5rem 1.5rem 1.5rem`,
    height: '100%',
    width: '100%',
    overflow: 'auto',

    xs: {
        padding: `1rem 1rem 0`
    }
});
