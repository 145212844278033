import * as React from 'react';
import useFelaEnhanced from 'common/hooks/useFelaEnhanced';

import AnimatedLogo from 'common/components/AnimatedLogo';
import * as felaRules from './Loader.styles.js';
import type { RulesExtend } from 'styles/theme';
import useTimeout from 'common/hooks/useTimeout';

export interface LoaderProps {
    extend?: RulesExtend<typeof felaRules>;
    children?: any;
    isVisible?: boolean;
    showAfter?: number;
    inline?: boolean;
}

const Loader: React.FC<LoaderProps> = ({
    children = null,
    extend,
    isVisible = false,
    showAfter = 500,
    inline = false
}) => {
    const [show, setShow] = React.useState<boolean>(
        showAfter === 0 || (showAfter > 0 && isVisible)
    );
    useTimeout(() => setShow(true), showAfter);

    const { rules, styles } = useFelaEnhanced(felaRules, { extend, inline });

    if (!show) {
        return children;
    }

    return (
        <div className={styles.container}>
            {/* @ts-expect-error xxx */}
            <AnimatedLogo extend={{ logo: rules.logo }} />
        </div>
    );
};

export default Loader;
