import { TRuleWithTheme } from 'styles/theme';

export const menuLeft: TRuleWithTheme = ({ theme }) => ({
    flexGrow: 0,
    marginRight: 'auto'
});

export const menuRight: TRuleWithTheme = ({ theme }) => ({
    flexGrow: 0,
    marginLeft: 'auto',

    marginTop: '-2px !important'
});
