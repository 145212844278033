import type { TablePaginationConfig } from 'antd';

const uiConfig = Object.freeze({
    header: {
        mobileSize: '48px'
    },
    sider: {
        mobileSize: '180px',
        size: '200px'
    },
    paginationSettings: {
        hideOnSinglePage: false,
        defaultPageSize: 10,
        responsive: true,
        showSizeChanger: true
    } as TablePaginationConfig,

    actionCellClassName: 'action-cell',
    actionCellContainerClassName: 'action-cell-container'
});

export default uiConfig;
