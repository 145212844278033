import routes, { anchors } from './routes.js';
import { isEnvDevelopment } from 'environments';

import packageJson from '../../package.json';
import preval from 'preval.macro';
import configDevelopment from 'config/config.development';
import configProduction from 'config/config.production';

const { DEV, REACT_APP_NAME } = import.meta.env;
// eslint-disable-next-line
const envConfig: any = DEV ? configDevelopment : configProduction;

const versionReleaseDate = preval`module.exports = new Date().toISOString()`;

export const config = {
    appName: REACT_APP_NAME,
    devTools: isEnvDevelopment,

    routes,
    anchors,

    version: packageJson.version || 'x.x.x',
    versionReleaseDate,

    apiEndpoints: {
        login: 'auth/login',
        logout: 'auth/logout',
        me: 'auth/me',
        refresh: 'auth/refresh',

        units: 'unit',
        unit: 'unit/:id',

        unitCache: 'unit/cache/:id',
        unitCacheEntries: 'unit/cache/:id/entries',
        unitsCache: 'unit/cache',
        unitsCacheSettings: 'unit/cache/settings',

        user: 'user/:id',
        userCloud: (id: string | number, idCloud: number | string) =>
            `user/${id}/cloud/${idCloud}`,
        userPassword: 'user/:id/password',
        users: 'user',

        clouds: 'cloud',
        cloud: 'cloud/:id'
    },

    apiBaseUrl: '#',

    sentry: {
        dsn: undefined
    },

    ...envConfig
};

config.allRoutesPath = (function rec(routesObj): string[] {
    const acc: string[] = [];
    const currentRoutes = Object.values<Record<string, any> | string>(
        routesObj
    );

    for (const route of currentRoutes) {
        if (typeof route === 'string') {
            acc.push(route);
        } else {
            acc.push(...rec(route));
        }
    }

    return acc;
})(config.routes);

export default config;
