import protectedAdminRoutes from './protectedAdminRoutes.js';
import protectedUserRoutes from './protectedUserRoutes.js';
import { Role } from 'interfaces/role.enum';
import NotFoundPage from 'pages/shared/NotFoundPage';
import { ProtectedRoute } from 'app/core/AppRouter/protectedRoutes/routes.interface';
import ProtectedRouteWrapper from 'app/core/AppRouter/protectedRoutes/ProtectedRouteWrapper';

const protectedRoutes: ProtectedRoute[] = [
    ...protectedUserRoutes,
    ...protectedAdminRoutes,
    {
        path: '*',
        element: ProtectedRouteWrapper(NotFoundPage),
        allowedRoles: [Role.User, Role.Admin]
    }
];

export default protectedRoutes;
