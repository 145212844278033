import type { Scope } from '@sentry/react';
import * as Sentry from '@sentry/react';
import type { RootState } from 'app/store';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Add user to every log
    configureScopeWithState(scope: Scope, state: RootState): void {
        scope.addEventProcessor((event) => {
            event.user = state.auth.user || undefined;
            return event;
        });
    }
});

export default sentryReduxEnhancer;
