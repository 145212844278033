import { Layout } from 'antd';
import type { PropsWithChildren } from 'react';

import * as felaRules from './PublicLayout.styles.js';
import useFelaEnhanced from 'common/hooks/useFelaEnhanced';
import Footer from 'features/shared/layout/PublicLayout/Footer';

const { Content } = Layout;

export default function PublicLayout({ children }: PropsWithChildren<any>) {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <Layout className={styles.layout}>
            {/*<Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
                <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb>*/}
            <Content className={styles.content}>{children}</Content>
            <Footer />
        </Layout>
    );
}
