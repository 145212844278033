import * as React from 'react';

import * as felaRules from './LingLogo.styles.js';
import useFelaEnhanced from 'common/hooks/useFelaEnhanced';
import Logo from 'common/components/Icons/Logo';
import { NavLink } from 'react-router-dom';
import useUserBasePath from 'features/shared/auth/hooks/useUserBasePath';

interface LingLogoProps extends Record<any, any> {
    extend?: typeof felaRules;
}

const LingLogo: React.FC<LingLogoProps> = ({ children, extend, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    const userBasePath = useUserBasePath();

    return (
        <NavLink className={styles.container} to={userBasePath}>
            <Logo {...props} />
        </NavLink>
    );
};

export default LingLogo;
