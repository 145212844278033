import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    '&:active': {
        backgroundColor: 'transparent !important',
        color: `${theme.colors.primary} !important`,

        '& a': {
            color: `${theme.colors.primary} !important`
        },
        '& span.ant-avatar': {
            color: `${theme.colors.white} !important`
        },
        '& span:not(.ant-avatar,.anticon)': {
            color: `${theme.colors.primary} !important`
        },
        '& i': {
            color: `${theme.colors.white} !important`
        }
    }
});

export const avatarContainer: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center'
});

export const avatarIcon: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.primary,
    fontSize: '16px',
    minWidth: '16px',
    width: '32px',
    height: '32px'
});

export const userFirstName: TRuleWithTheme = ({ theme }) => ({
    paddingLeft: '0.25rem'
});

export const userLastName: TRuleWithTheme = ({ theme }) => ({
    xs: {
        display: 'none'
    }
});
