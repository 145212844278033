import { useEffect } from 'react';
import * as React from 'react';

import useNetworkStatus from 'features/shared/network/hooks/useNetworkStatus';
import { notification } from 'antd';
import { t } from '@lingui/macro';

const NetworkCheck: React.FC = () => {
    const { isOnline } = useNetworkStatus();
    const previousStatus = React.useRef(isOnline);

    useEffect(() => {
        if (previousStatus.current === isOnline) {
            return;
        }

        const id = setTimeout(() => {
            if (!isOnline && previousStatus.current) {
                notification.error({
                    placement: 'topRight',
                    message: t`Nejste připojeni k internetu!`,
                    description: t`Pravděpodobně nejste připojeni k internetu.`
                });
            } else if (isOnline && !previousStatus.current) {
                notification.success({
                    placement: 'topRight',
                    message: t`Jste připojeni!`,
                    description: t`Vypadá to, že internetové připojení je již v pořádku.`
                });
            }

            previousStatus.current = isOnline;
        }, 5000);

        return () => clearTimeout(id);
    }, [isOnline]);

    return null;
};

export default NetworkCheck;
