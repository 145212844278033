export const valOrSlash = (value?: string | null) =>
    value === undefined || value === null ? '–' : value;

export const valOrFallback = (value: string | null, fallback?: string) =>
    value === undefined || value === null ? fallback : value;

export const numberOrUndefined = (value?: any): number | undefined => {
    if (value === null || value === undefined) {
        return undefined;
    }

    const number = Number(value);
    return !isNaN(number) && isFinite(number) ? number : undefined;
};
