import { Button as AntdButton, Result as RequestResult } from 'antd';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import Routes from 'config/routes';

export default function NotFoundPage() {
    return (
        <RequestResult
            status="404"
            title="404"
            subTitle={<Trans>Požadovaná stránka neexistuje!</Trans>}
            extra={
                <AntdButton>
                    <Link to={Routes.home}>
                        <Trans>Vrátit se na domovskou obrazovku</Trans>
                    </Link>
                </AntdButton>
            }
        />
    );
}
