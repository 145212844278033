import config from 'config/index.js';
import { Role } from 'interfaces/role.enum';
import { Navigate, useParams } from 'react-router-dom';
import { lazy } from 'react';
import type { ProtectedRoute } from 'app/core/AppRouter/protectedRoutes/routes.interface';
import CanWithErrorPage from 'features/shared/auth/CanWithErrorPage';
import { Action } from 'features/shared/auth/auth.config';
import { numberOrUndefined } from 'common/utils/basic';
import ProtectedRouteWrapper from 'app/core/AppRouter/protectedRoutes/ProtectedRouteWrapper';

const AdminDashboard = lazy(() => import('pages/admin/Dashboard'));
const AdminUnitsCache = lazy(() => import('pages/admin/units/Cache'));
const AdminUnits = lazy(() => import('pages/admin/units/Normal'));
const AdminUsersTablePage = lazy(
    () => import('pages/admin/users/UsersTablePage')
);

const AdminCloudsTablePage = lazy(
    () => import('pages/admin/clouds/CloudsTablePage')
);

const CloudDetailPage = lazy(
    () => import('pages/admin/clouds/CloudsDetailPage')
);

const ProfileSettingsPage = lazy(
    () => import('pages/user/ProfileSettings/ProfileSettingsPage')
);

const ProfileSettingsPageHandler = () => {
    const params = useParams();

    return (
        <CanWithErrorPage
            children={null}
            do={Action.Detail}
            on={{ id_cuzivatel: numberOrUndefined(params.id) }}
            a={'User'}
            field={'id_cuzivatel'}
            render={() => (
                <ProfileSettingsPage userId={numberOrUndefined(params.id)!} />
            )}
        />
    );
};

const CloudsDetailHandler = () => {
    const params = useParams();

    return (
        <CanWithErrorPage
            children={null}
            do={Action.Detail}
            on={{ id_cuzivatel: numberOrUndefined(params.id) }}
            a={'Cloud'}
            field={'id_cloud'}
            render={() => (
                <CloudDetailPage cloudId={numberOrUndefined(params.id)!} />
            )}
        />
    );
};

const AdminSettingsCacheUnits = lazy(
    () => import('pages/admin/settings/SettingsCacheUnits')
);

const protectedAdminRoutes: ProtectedRoute[] = [
    {
        path: config.routes.admin.dashboard,
        element: ProtectedRouteWrapper(AdminDashboard),
        allowedRoles: [Role.Admin]
    },
    {
        path: config.routes.admin.unitsKronos,
        element: ProtectedRouteWrapper(AdminUnits),
        allowedRoles: [Role.Admin]
    },
    {
        path: config.routes.admin.unitsFarmsoft,
        element: ProtectedRouteWrapper(AdminUnitsCache),
        allowedRoles: [Role.Admin]
    },
    {
        path: config.routes.admin.userProfile,
        element: ProtectedRouteWrapper(ProfileSettingsPageHandler),
        allowedRoles: [Role.User, Role.Admin]
    },
    {
        path: config.routes.admin.users,
        element: ProtectedRouteWrapper(AdminUsersTablePage),
        allowedRoles: [Role.Admin]
    },
    {
        path: config.routes.admin.clouds,
        element: ProtectedRouteWrapper(AdminCloudsTablePage),
        allowedRoles: [Role.Admin]
    },
    {
        path: config.routes.admin.cloudsDetail,
        element: ProtectedRouteWrapper(CloudsDetailHandler),
        allowedRoles: [Role.User, Role.Admin]
    },
    {
        path: config.routes.admin.settings.unitsFarmsoft,
        element: ProtectedRouteWrapper(AdminSettingsCacheUnits),
        allowedRoles: [Role.Admin]
    },

    // Add Navigates
    {
        path: '/',
        element: <Navigate to={config.routes.admin.dashboard} />,
        allowedRoles: [Role.Admin]
    },
    {
        path: config.routes.home,
        element: <Navigate to={config.routes.admin.dashboard} />,
        allowedRoles: [Role.Admin]
    }
];

export default protectedAdminRoutes;
