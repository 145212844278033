function removeTimeZone(date: string): string;
function removeTimeZone(date: Date): Date;
function removeTimeZone(date: string | Date): string | Date {
    if (!date) {
        return date;
    }

    if (typeof date === 'string') {
        return date.replace(/Z$/, '');
    }

    return new Date(removeTimeZone(date.toISOString()));
}

export default removeTimeZone;
