export default function Logo(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            height={64}
            viewBox="0 0 658.556 359.776"
            version={1.1}
            {...props}
        >
            <defs>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 -.523 1.724)"
                    spreadMethod="pad"
                    id="prefix__b"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 -.398 1.63)"
                    spreadMethod="pad"
                    id="prefix__c"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 -.406 1.636)"
                    spreadMethod="pad"
                    id="prefix__d"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 .45 .991)"
                    spreadMethod="pad"
                    id="prefix__e"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 .254 1.139)"
                    spreadMethod="pad"
                    id="prefix__f"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 -.194 1.476)"
                    spreadMethod="pad"
                    id="prefix__g"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 -.645 1.816)"
                    spreadMethod="pad"
                    id="prefix__h"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 .04 1.3)"
                    spreadMethod="pad"
                    id="prefix__i"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 -.422 1.648)"
                    spreadMethod="pad"
                    id="prefix__j"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 -.43 1.653)"
                    spreadMethod="pad"
                    id="prefix__k"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 .04 1.3)"
                    spreadMethod="pad"
                    id="prefix__l"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 -.416 1.643)"
                    spreadMethod="pad"
                    id="prefix__m"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 -.32 1.571)"
                    spreadMethod="pad"
                    id="prefix__n"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientTransform="scale(-195.7958 195.7958) rotate(-73.968 .019 1.316)"
                    spreadMethod="pad"
                    id="prefix__o"
                    xlinkHref="#prefix__a"
                >
                    <stop offset={0} stopColor="#91cb25" />
                    <stop offset={1} stopColor="#026a38" />
                </linearGradient>
                <linearGradient id="prefix__a" gradientUnits="userSpaceOnUse" />
            </defs>
            <path
                d="M391.648 133.357l-10.748-12.33c15.553 4.989 27.456 18.19 30.599 34.473-5.778-5.922-12.43-13.586-19.851-22.143"
                fill="url(#prefix__b)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M333.389 194.393l12.551-2.169a34.864 34.864 0 0021.089 7.057c13.282 0 24.862-7.402 30.844-18.291l12.328-3.103c-5.84 18.271-22.995 31.532-43.172 31.532-13.343 0-25.367-5.799-33.64-15.026"
                fill="url(#prefix__c)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M301.653 198.652l10.95-1.257c11.234 18.311 31.431 30.538 54.426 30.538 35.203 0 63.815-28.613 63.815-63.794 0-21.764-10.942-40.997-27.61-52.519l8.023-.073a41.49 41.49 0 007.066-.608c13.954 13.459 22.66 32.326 22.66 53.2 0 40.759-33.175 73.933-73.954 73.933-28.308 0-52.966-16-65.376-39.42"
                fill="url(#prefix__d)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M86.226 101.419L65.203 71.555v29.864H49.486V32.654h15.717v31.827l22.005-31.827h17.88L80.921 67.919l23.182 33.5z"
                fill="url(#prefix__e)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M111.011 101.419V32.654h15.718v22.593h7.465l12.967-22.593h16.898l-14.636 25.245c7.561 3.835 12.671 11.493 12.671 19.942a23.52 23.52 0 01-23.576 23.578zm15.718-14.736h11.789c4.322 0 7.858-3.829 7.858-8.842 0-5.007-3.536-8.842-7.858-8.842h-11.789z"
                fill="url(#prefix__f)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M286.23 101.419v-37.33l-26.521 37.33h-11.788V32.654h15.714v37.33l26.526-37.33h11.789v68.765z"
                fill="url(#prefix__g)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M388.166 81.869c0-13.166 10.02-18.076 20.239-20.92 10.315-2.851 14.249-4.523 14.249-8.745 0-2.652-1.867-5.599-9.339-5.599-7.761 0-11.982 3.829-14.44 9.524l-13.552-7.858c3.829-10.115 13.064-16.994 27.503-16.994 14.93 0 25.541 7.863 25.541 21.12 0 14.543-11.692 18.178-22.104 21.319-10.709 3.243-12.375 5.406-12.375 8.546 0 2.754 2.452 5.206 7.369 5.206 6.287 0 9.53-3.044 11.884-7.954l13.263 7.761c-5.012 10.116-13.854 15.521-25.147 15.521-11.887 0-23.091-7.664-23.091-20.927"
                fill="url(#prefix__h)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M167.527 67.036c0-20.238 16.011-35.759 35.754-35.759 19.749 0 35.76 15.521 35.76 35.759 0 20.239-16.011 35.76-35.76 35.76-19.743 0-35.754-15.521-35.754-35.76m6.987.001c0 16.133 12.636 28.772 28.767 28.772 16.136 0 28.773-12.639 28.773-28.772 0-16.136-12.637-28.773-28.773-28.773-16.131 0-28.767 12.637-28.767 28.773"
                fill="url(#prefix__i)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M311.258 72.434h10.45a1.99 1.99 0 001.723-.994l3.739-6.461 6.566 30.045c.204.93 1.025 1.57 1.989 1.565a1.993 1.993 0 001.918-1.652l7.57-43.906 3.365 28.99a1.99 1.99 0 001.551 1.714 1.99 1.99 0 002.126-.905l5.128-8.396h24.52c-2.562 17.527-17.378 30.362-35.319 30.362-17.947 0-32.764-12.835-35.326-30.362"
                fill="url(#prefix__j)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M329.963 59.113a1.99 1.99 0 00-1.637-1.544 1.993 1.993 0 00-2.03.971l-5.737 9.911h-9.666l-.069-1.415c0-20.238 16.011-35.759 35.76-35.759 19.742 0 35.753 15.521 35.753 35.759 0 .479-.052.942-.069 1.415h-26.002c-.694 0-1.338.362-1.7.953l-2.698 4.419-4.292-36.973a1.99 1.99 0 00-1.923-1.761l-.056-.001c-.966 0-1.796.696-1.962 1.654l-8.184 47.475z"
                fill="url(#prefix__k)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M178.89 67.037c0-13.68 10.713-24.397 24.391-24.397 13.68 0 24.397 10.717 24.397 24.397 0 13.68-10.717 24.395-24.397 24.395-13.678 0-24.391-10.715-24.391-24.395m9.089-.001c0 8.664 6.853 15.308 15.304 15.308 8.455 0 15.306-6.644 15.306-15.308 0-8.663-6.851-15.307-15.306-15.307-8.451 0-15.304 6.644-15.304 15.307"
                fill="url(#prefix__l)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M331.888 164.139c0-19.387 15.755-35.163 35.141-35.163 4.855 0 9.725 1.111 13.949 2.914 11.943 13.717 22.036 25.705 30.399 33.408 1.773 1.631.788 4.761-1.432 5.337-25.886 6.704-49.202 11.805-70.295 15.484-4.784-6.004-7.762-13.747-7.762-21.98"
                fill="url(#prefix__m)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M309.014 190.662a63.444 63.444 0 01-5.78-26.523c0-22.375 11.563-42.065 29.008-53.455a64.378 64.378 0 0014.342 1.594c5.105 0 9.97-.927 14.495-2.562l8.498 9.268c-.791-.062-1.808-.147-2.548-.147-24.961 0-45.28 20.318-45.28 45.302 0 8.565 2.6 16.984 6.75 23.826a548.988 548.988 0 01-19.483 2.707"
                fill="url(#prefix__n)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
            <path
                d="M122.585 110.171h15.932c1.342 0 2.66-.096 3.959-.256l3.587 6.493c5.862 9.986 12.795 19.467 21.027 27.879 8.183 8.437 17.661 15.843 28.21 21.413 10.492 5.559 22.26 9.234 34.085 9.036l.23-.881-7.996-2.632c-2.615-1.008-5.154-2.216-7.73-3.311-2.563-1.13-4.973-2.586-7.466-3.861-1.257-.622-2.444-1.369-3.618-2.137l-3.546-2.262c-9.349-6.201-17.852-13.626-25.64-21.707-7.862-8.022-15.035-16.721-21.686-25.796l-2.747-3.798a32.51 32.51 0 0017.828-15.271c8.002 11.24 21.122 18.467 36.266 18.467 14.912 0 27.853-7.009 35.889-17.95v7.822a8.753 8.753 0 008.753 8.752h11.787a8.75 8.75 0 007.136-3.683l10.631-14.966v9.897a8.753 8.753 0 008.754 8.752h15.719c4.833 0 11.791-2.542 11.791-7.375s2.364 1.848 6.781 3.912c-16.714 13.572-27.425 34.275-27.425 57.431 0 9.706 1.953 19.171 5.375 27.683-12.501 1.21-24.039 1.79-34.713 1.79-84.317 0-114.765-36.174-141.173-83.441"
                fill="url(#prefix__o)"
                transform="matrix(1.33333 0 0 -1.33333 0 359.776)"
            />
        </svg>
    );
}
