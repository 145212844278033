import { ReactNode } from 'react';
import * as React from 'react';
import { useLingui } from '@lingui/react';
import removeTimeZone from './utils/removeTimeZone.js';

export interface DateFormatProps {
    value: string | Date;
    format?: Intl.DateTimeFormatOptions;
    stripTimeZone?: boolean;
    noWrap?: boolean;
    fallbackTxt?: ReactNode;
}

const DateFormat: React.FC<DateFormatProps> = ({
    value,
    stripTimeZone = false,
    format,
    noWrap = false,
    fallbackTxt = '–'
}) => {
    const { i18n } = useLingui();

    const date = stripTimeZone ? removeTimeZone(value as any) : value;

    const formattedDate = value ? i18n.date(date, format) : fallbackTxt;

    return noWrap ? (
        <span style={{ whiteSpace: 'nowrap' }}>{formattedDate}</span>
    ) : (
        <>{formattedDate}</>
    );
};

export default DateFormat;
