// IndexedDb store
import { clear, createStore, get, update } from 'idb-keyval';
import logger from 'common/utils/logger';

export const userPreferencesTableStore = createStore(
    'tables',
    'user-preferences'
);

export const updateTableSettings = (
    key: IDBValidKey,
    updater: (oldValue?: any) => any
) =>
    update(key, updater, userPreferencesTableStore)
        .then(() => logger.info(`Table settings updated!`))
        .catch((e) => logger.error(e));

export const getTableSettings = (key: IDBValidKey) =>
    get(key, userPreferencesTableStore);

export const clearTableSettings = () => clear(userPreferencesTableStore);
