import { TRule } from 'fela';

const theme = {
    colors: {
        white: '#fff',
        black: '#000',
        primary: '#099b15',
        primaryIcon: '#25a82c',
        grey: '#F9F9F9',
        borderColor: '#f0f0f0'
    }
};

export type Theme = typeof theme;
export type ThemeProps = { theme: Theme };

export type TRuleWithTheme<Props = NonNullable<unknown>> = TRule<
    ThemeProps & Props
>;

export type RulesExtend<
    TExtendableRules,
    TProps = NonNullable<unknown>
> = Partial<Record<keyof TExtendableRules, TRuleWithTheme<TProps>>>;

export default theme;
