import * as React from 'react';

import * as felaRules from './Header.styles.js';
import useFelaEnhanced from 'common/hooks/useFelaEnhanced';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import Navbar from 'features/shared/layout/AppLayout/Navbar';
import LinkLogo from 'common/components/LinkLogo';

interface HeaderProps {
    extend?: typeof felaRules;
    toggleSidebarVisibility: () => void;
}

const Header: React.FC<HeaderProps> = ({ extend, toggleSidebarVisibility }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <Layout.Header className={styles.header}>
            <div className={styles.content}>
                <Button
                    className={styles.sidebarToggle}
                    shape="circle"
                    icon={<MenuOutlined />}
                    onClick={toggleSidebarVisibility}
                    ghost
                />
                <LinkLogo className={styles.logo} />
            </div>

            <Navbar />
        </Layout.Header>
    );
};

export default Header;
