const namedKeys = {
    xs: '@media (max-width: 576px)', // mobile
    sm: '@media (min-width: 577px)',
    md: '@media (min-width: 768px)',
    lg: '@media (min-width: 992px)', // tablet
    xl: '@media (min-width: 1200px)', // desktop
    xxl: '@media (min-width: 1600px)',

    lessLg: '@media (max-width: 991px)'
};

export type FelaNamedKeys = keyof typeof namedKeys;
export type FelaNamedKeysValues = (typeof namedKeys)[FelaNamedKeys];

export default namedKeys;
