import { useState, useLayoutEffect } from 'react';
import { CoreProps } from './types.js';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { defaultLocale, supportedLocales } from 'config/i18n.js';
import ConfigProvider from 'antd/es/config-provider/index.js';
import moment from 'moment';
import { Locale } from 'antd/es/locale';
import 'moment/locale/cs.js';
import 'moment/locale/en-gb.js';

const languageImportMap: Record<supportedLocales, () => Promise<unknown>> = {
    [supportedLocales.CS]: () => import('antd/es/locale/cs_CZ.js'),
    [supportedLocales.EN]: () => import('antd/es/locale/en_GB.js')
};

const setMomentLocale: Record<supportedLocales, () => Promise<void>> = {
    [supportedLocales.CS]: async () => {
        moment.locale('cs');
    },
    [supportedLocales.EN]: async () => {
        moment.locale('en-gb');
    }
};

export async function changeLanguage(
    locale: supportedLocales
): Promise<Locale> {
    const { messages } = await import(
        `../../translations/${locale}/messages.po`
    );
    console.info(`Setting language to "${locale}"`);

    i18n.load(locale, messages);
    i18n.activate(locale);

    await setMomentLocale[locale]();
    return (await languageImportMap[locale]()) as Locale;
}

const Lingui = ({ children }: CoreProps) => {
    const [loaded, setLoaded] = useState(false);
    const [locale, setLocale] = useState<Locale>();

    useLayoutEffect(() => {
        changeLanguage(defaultLocale)
            .then((newLocale) => {
                setLocale(newLocale);
                setLoaded(true);
            })
            .catch((err) => console.error('Failed to load language!', err));
    }, []);

    if (!loaded) {
        return null;
    }

    return (
        <I18nProvider i18n={i18n}>
            <ConfigProvider locale={locale}>{children}</ConfigProvider>
        </I18nProvider>
    );
};

export default Lingui;
