import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme<{ inline?: boolean }> = ({
    inline = true
}) => ({
    position: inline ? 'relative' : 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundColor: `#f9fafc`,
    zIndex: 999
});

export const logo: TRuleWithTheme<{ inline?: boolean }> = ({ inline }) => ({
    margin: 'auto',
    width: '100%',

    md: {
        width: '35vw',
        minWidth: `260px`
    },

    maxWidth: '100%',
    maxHeight: '100%',
    height: 'auto',
    padding: '2rem'
});
