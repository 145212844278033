/**
 * Default tags used by the cacher helpers
 */
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { TagTypes } from 'app/services/api';

const defaultTags = [TagTypes.UNAUTHORIZED, TagTypes.UNKNOWN_ERROR] as const;
type DefaultTags = (typeof defaultTags)[number];

function concatErrorCache<T, ID>(
    existingCache: CacheList<T, ID>,
    error: FetchBaseQueryError | undefined
): CacheList<T, ID> {
    if (error && 'status' in error && error.status === 401) {
        // unauthorized error
        return [...existingCache, TagTypes.UNAUTHORIZED];
    }

    // unknown error
    return [...existingCache, TagTypes.UNKNOWN_ERROR];
}

/**
 * An individual cache item
 */
export type CacheItem<T, ID> = { type: T; id: ID };

/**
 * A list of cache items, including a LIST entity cache
 */
export type CacheList<T, ID> = (
    | CacheItem<T, 'LIST'>
    | CacheItem<T, ID>
    | DefaultTags
)[];

/**
 * Inner function returned by `providesList` to be passed to the `provides` property of a query
 */
type InnerProvidesList<T, K extends keyof any> = <
    Results extends { [k in K]: any }[],
    Error extends FetchBaseQueryError
>(
    results: Results | undefined,
    error: Error | undefined
) => CacheList<T, Results[number][K]>;

/**
 * HOF to create an entity cache to provide a LIST,
 * depending on the results being in a common format.
 *
 * Will not provide individual items without a result.
 *
 * @example
 * ```ts
 * const results = [
 *   { id: 1, message: 'foo' },
 *   { id: 2, message: 'bar' }
 * ]
 * providesList('Todo')(results)
 * // [
 * //   { type: 'Todo', id: 'List'},
 * //   { type: 'Todo', id: 1 },
 * //   { type: 'Todo', id: 2 },
 * // ]
 * ```
 */
export const providesList =
    <E extends {}, T = TagTypes>(
        type: T,
        propertyKey: keyof E
    ): InnerProvidesList<T, typeof propertyKey> =>
    (results, error) => {
        // is result available?
        if (results) {
            // successful query
            return [
                { type, id: 'LIST' },
                ...results.map(
                    ({ [propertyKey]: id }) => ({ type, id }) as const
                )
            ];
        }
        // Received an error, include an error cache item to the cache list
        return concatErrorCache([{ type, id: 'LIST' }], error);
    };

export const cacheByProperty =
    <E, T = TagTypes>(type: T, propertyKey: keyof E) =>
    <ID, Result extends { [k in typeof propertyKey as k]: E[k] }>(
        result: Result | undefined
    ): [CacheItem<T, ID>] | [DefaultTags] => {
        if (result) {
            return [
                {
                    id: result[propertyKey] as unknown as ID,
                    type: type
                }
            ];
        }

        return [TagTypes.UNKNOWN_ERROR];
    };

/**
 * HOF to create an entity cache to invalidate a LIST.
 *
 * Invalidates regardless of result.
 *
 * @example
 * ```ts
 * invalidatesList('Todo')()
 * // [{ type: 'Todo', id: 'List' }]
 * ```
 */
export const invalidatesList =
    <T extends string>(type: T) =>
    <Result = undefined, Error = undefined>(
        result: Result,
        error: Error
    ): readonly [CacheItem<T, 'LIST'>] | readonly [] => {
        if (error) {
            return [];
        }
        return [{ type, id: 'LIST' }] as const;
    };

/**
 * HOF to create an entity cache for a single item using the query argument as the ID.
 *
 * @example
 * ```ts
 * cacheByIdArg('Todo')({ id: 5, message: 'walk the fish' }, undefined, 5)
 * // returns:
 * // [{ type: 'Todo', id: 5 }]
 * ```
 */
export const cacheByIdArg =
    <T = TagTypes>(type: T) =>
    <ID, Result = undefined, Error = undefined>(
        result: Result,
        error: Error,
        id: ID
    ): readonly [CacheItem<T, ID>] | readonly [] => {
        if (error) {
            return [];
        }
        return [{ type, id }] as const;
    };

/**
 * HOF to create an entity cache for a single item using the id property from the query argument as the ID.
 *
 * @example
 * ```ts
 * cacheByIdArgProperty('Todo')(undefined, { id: 5, message: 'sweep up' })
 * // returns:
 * // [{ type: 'Todo', id: 5 }]
 * ```
 */
export const cacheByIdArgProperty =
    <E extends {}, T = TagTypes>(type: T, propertyKey: keyof E) =>
    <
        Arg extends { [k in typeof propertyKey as k]: E[k] },
        Result = undefined,
        Error = undefined
    >(
        result: Result,
        error: Error,
        arg: Arg
    ): readonly [CacheItem<T, Arg[typeof propertyKey]>] | [] => {
        if (error) {
            return [];
        }
        return [{ type, id: arg[propertyKey] }] as const;
    };

/**
 * HOF to invalidate the TagTypes.UNAUTHORIZED type cache item.
 */
export const invalidatesUnauthorized =
    () =>
    <Arg = undefined, Result = undefined, Error = undefined>(
        result: Result,
        error: Error,
        arg: Arg
    ): [TagTypes.UNAUTHORIZED] => [TagTypes.UNAUTHORIZED];

/**
 * HOF to invalidate the TagTypes.UNKNOWN_ERROR type cache item.
 */
export const invalidatesUnknownErrors =
    () =>
    <Arg = undefined, Result = undefined, Error = undefined>(
        result: Result,
        error: Error,
        arg: Arg
    ): [TagTypes.UNKNOWN_ERROR] => [TagTypes.UNKNOWN_ERROR];

/**
 * Utility helpers for common provides/invalidates scenarios
 */
export const cacher = {
    defaultTags,
    providesList,
    invalidatesList,
    cacheByIdArg,
    cacheByIdArgProperty,
    invalidatesUnauthorized,
    invalidatesUnknownErrors,
    cacheByProperty
};
