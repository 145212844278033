import * as React from 'react';
import useFelaEnhanced from 'common/hooks/useFelaEnhanced';
import { NavLink, NavLinkProps } from 'react-router-dom';
import * as felaRules from './MenuLink.styles.js';

interface ItemProps
    extends Omit<NavLinkProps, 'className' | 'activeClassName'> {
    extend?: typeof felaRules;
}

const MenuLink: React.FC<ItemProps> = ({ children, extend, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <NavLink
            {...props}
            key={String(props.to)}
            className={({ isActive }) =>
                [
                    styles.link,
                    ...(isActive ? [styles.activeLink, 'active'] : [])
                ].join(' ')
            }
        >
            {children}
        </NavLink>
    );
};

export default MenuLink;
