import { createSlice } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from 'app/store';

export interface NetworkState {
    isOnline: boolean;
    lastChecked: number | null;
}

const slice = createSlice({
    name: 'network',
    initialState: {
        lastChecked: null,
        isOnline: typeof window !== 'undefined' && navigator.onLine
    } as NetworkState,
    reducers: {
        online(state) {
            state.lastChecked = Date.now();
            state.isOnline = true;
        },
        offline(state) {
            state.lastChecked = Date.now();
            state.isOnline = false;
        }
    }
});

export default slice.reducer;

export const selectNetworkState = (state: RootState): NetworkState =>
    state.network;

// Auto infer
export function monitorNetworkState(dispatch: AppDispatch) {
    const onlineHandler = () => dispatch(slice.actions.online());
    const offlineHandler = () => dispatch(slice.actions.offline());

    if (navigator.onLine) {
        onlineHandler();
    } else {
        offlineHandler();
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);
}
