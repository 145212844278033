import api, { TagTypes } from 'app/services/api';
import {
    LoginRequest,
    UserResponse
} from 'app/services/interfaces/auth.interface';
import config from 'config/index.js';
import { notification } from 'antd';
import logger from 'common/utils/logger';
import { t } from '@lingui/macro';
import { clearTableSettings } from 'features/shared/user-preferences/store';
import { isEnvDevelopment } from 'environments';
import type { User } from 'interfaces/User.entity';
import {
    cacheByProperty,
    invalidatesUnauthorized
} from '../utils/rtkQueryCacheUtils.js';
import { push } from 'redux-first-history';

export const authApi = api.injectEndpoints({
    overrideExisting: isEnvDevelopment,
    endpoints: (builder) => ({
        login: builder.mutation<User, LoginRequest>({
            query: (credentials) => ({
                url: config.apiEndpoints.login,
                method: 'POST',
                body: credentials
            }),
            invalidatesTags: invalidatesUnauthorized(),
            transformResponse: (response: UserResponse) => response?.user,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(push(config.routes.home));

                    notification.success({
                        placement: 'topRight',
                        message: t`Vitejte ve vyhodnocovacím systému vitality!`
                    });
                } catch (e) {
                    if ((e as any).error.status === 401) {
                        notification.error({
                            placement: 'topRight',
                            message: t`Přihlášení se nezdařilo!`,
                            description: t`Špatné přihlašovací údaje!`
                        });
                    }
                    logger.error(e);
                }
            }
        }),
        logout: builder.mutation<any, any>({
            query: () => ({
                url: config.apiEndpoints.logout,
                method: 'POST'
            }),
            async onQueryStarted(
                arg,
                { dispatch, queryFulfilled }
            ): Promise<void> {
                try {
                    await queryFulfilled;

                    dispatch(push(config.routes.login));

                    notification.success({
                        placement: 'topRight',
                        message: t`Odhlášení proběhlo úspěšně`
                    });

                    await clearTableSettings();
                } catch (err) {
                    notification.error({
                        placement: 'topRight',
                        message: t`Nepodařilo se nám Vás odhlásit`
                    });
                    logger.error(err);
                }
            }
        }),
        getMe: builder.query<User, undefined>({
            query: () => ({
                url: config.apiEndpoints.me,
                method: 'GET'
            }),
            providesTags: cacheByProperty<User>(TagTypes.Users, 'id_cuzivatel')
        })
    })
});

export const { useLoginMutation, useGetMeQuery, useLogoutMutation } = authApi;
