import { TRuleWithTheme } from 'styles/theme';
import { TRule } from 'fela';
import uiConfig from 'features/shared/ui/ui.config';

export const container: TRuleWithTheme = ({ theme }) => ({});

export const content: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5rem',
    float: 'left',
    overflow: 'visible',
    height: '100%'
});

export const sidebarToggle: TRuleWithTheme = () => ({
    display: 'none',

    xs: {
        marginLeft: '16px',
        display: 'block'
    }
});

export const header: TRuleWithTheme = ({ theme }) => ({
    background: theme.colors.white,
    borderBottom: `1px solid #f0f0f0`,
    position: 'relative',
    zIndex: 2,
    padding: `0 0.5rem`,

    xs: {
        height: uiConfig.header.mobileSize,
        lineHeight: uiConfig.header.mobileSize,

        paddingLeft: 0,
        paddingRight: 0,

        '& .ant-menu-overflow-item': {
            marginLeft: 'auto'
        }
    },

    sm: {
        padding: `0 1rem`
    },

    xl: {
        padding: `0 1rem`
    }
});

export const logo: TRule = () => ({
    maxHeight: '100%',
    float: 'left',
    overflow: 'hidden'
});
